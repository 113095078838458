import React from "react";
import Button from "components/Button";
import { connect, ConnectedProps } from "react-redux";
import Header from "components/Header";
import KeywordsTable from "components/KeywordsTable";
import LocationFilter from "components/LocationFilter";
import { SuggestedKeywordsPage } from "./styled";
import { RootState } from "store/store";
import api from "services/api";
import { EXPORT_FILE_TYPE } from "constants/types";
import { messages } from "constants/utility/messages";
import useKeywordsTableHook from "hooks/useKeywordsTable";

const toolTipTitle = {
  titlePage: "Relevant Keyword Suggestion",
  titleKeywords: "Suggested Results",
  titleSaveList: "Save/Create Keyword List",
};

const mapStateToProps = ({ keywordsList }: RootState) => ({
  keywordsList,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ISuggestKeywordsProps extends PropsFromRedux {}

const SuggestedKeywords: React.FunctionComponent<ISuggestKeywordsProps> = ({ keywordsList }) => {
  const [selectedCountry, setSelectedCountry] = React.useState<{ name: string } | null>(null);
  const [selectedCity, setSelectedCity] = React.useState<{ name: string } | null>(null);
  const [searchedKeyword, setSearchedKeyword] = React.useState<string | null>(null);
  const [selectedKeywords, setSelectedKeywords] = React.useState<Array<string>>([]);
  const { suggestedKeywords, loading, fetchSuggestedKeywords } = useKeywordsTableHook();

  const onKeywordSearch = React.useCallback((keyword: string) => {
    if (keyword) {
      setSearchedKeyword(keyword);
      fetchSuggestedKeywords(keyword, selectedCity ? selectedCity.name : selectedCountry?.name);
    }
  }, []);

  const onKeywordSelect = (selectedKeyword: string) => {
    let newKeywordsList = [];
    if (selectedKeywords.includes(selectedKeyword)) {
      newKeywordsList = selectedKeywords.filter((element) => element !== selectedKeyword);
    } else {
      newKeywordsList = [...selectedKeywords, selectedKeyword];
    }

    setSelectedKeywords(newKeywordsList);
  };

  function renderSuggestionFiltersTab(searchTerms: string) {
    return (
      <div className="suggest-location-filter">
        <LocationFilter
          searchTerms={searchTerms}
          onCountryChange={(values: any) => setSelectedCountry(values[0])}
          onCityChange={(values: any) => setSelectedCity(values[0])}
          onSubmit={() => onKeywordSearch(searchTerms)}
        />
      </div>
    );
  }

  return (
    <SuggestedKeywordsPage>
      <h6 className="keyword-tool-title">Keyword Tools</h6>
      <h2 className="page-title">Suggested</h2>
      <Header
        searchField="input"
        searchFieldPlaceholder="Search Keywords"
        onSearchFormSubmit={onKeywordSearch}
        searchFieldDisabled={loading}
      >
        {(searchTerms) => renderSuggestionFiltersTab(searchTerms)}
      </Header>
      {searchedKeyword && (
        <div className="suggested-keywords-wrapper">
          <div className="tableWrapper">
            <KeywordsTable
              fullHeight
              KeywordsSuggested={messages.KeywordsSuggested.content}
              keywords={suggestedKeywords}
              // searchedKeyword={searchedKeyword}
              selectedKeys={selectedKeywords}
              isLoading={loading}
              onKeywordSelect={onKeywordSelect}
              selectAll={(selectedTableData) =>
                setSelectedKeywords((prev) => Array.from(new Set([...prev, ...selectedTableData])))
              }
              unSelectAll={(unselectedTableData) =>
                setSelectedKeywords((prev) =>
                  prev.filter((item) => !unselectedTableData.includes(item))
                )
              }
            />
          </div>
        </div>
      )}
    </SuggestedKeywordsPage>
  );
};

export default connector(SuggestedKeywords);
