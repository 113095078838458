import styled from "styled-components/macro";
import { Modal } from "react-bootstrap";
const icon = require("assets/home-screen/folder-down-icon.svg").default;

export const KeywordListPopupModal = styled(Modal)`
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  .modal-content {
    color: #ffffff;
    background-color: ${({ theme }) => theme.nav};
    border-radius: 20px;
    border: 1px solid ${({ theme }) => theme.dark};
    padding-top: 10px;
  }

  .modal-header {
    padding-bottom: 0;
    border-bottom: none;
    justify-content: center;

    .modal-title {
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.secondary};
    }
  }

  .modal-body {
    padding: 40px 45px;

    .input-wrapper {
      position: relative;

      .input-text {
        background-color: ${({ theme }) => theme.dropdown};
      }

      .keyword-list-selector {
        position: absolute;
        list-style: none;
        background: ${({ theme }) => theme.dropdown};
        z-index: 1;
        width: 100%;
        padding: 0;
        max-height: 300px;
        overflow-y: auto;

        .keyword-list-item {
          padding: 10px;
          font-size: 12px;
          cursor: pointer;
          background: ${({ theme }) => theme.dropdown};
          color: ${({ theme }) => theme.contrast};
          height: 40px;

          &:hover {
            background: ${({ theme }) => theme.dark};
          }
        }
      }

      .select {
        cursor: pointer;

        &:after {
          position: absolute;
          content: "";
          top: 7px;
          right: 0px;
          padding: 15px 25px;
          background-image: url(${icon});
          background-repeat: no-repeat;
          background-size: 15px 15px;
          background-position: center;
          border-left: 1px solid ${({ theme }) => theme.checkboxBorder};
          filter: brightness(0.5);
        }
      }
    }

    .keyword-list-label {
      margin-bottom: 10px;
      color: ${({ theme }) => theme.contrast};
    }
  }

  .footer-bar {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .action-buttons {
      display: flex;
      gap: 10px;
      justify-content: end;
    }
  }
`;
