import Button from "components/Button";
import { TrashIcon } from "components/Icons";
import FolderDownIcon from "components/Icons/FolderDownIcon";
import FolderUpIcon from "components/Icons/FolderUpIcon";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteHomeCampaign } from "store/actions/campaigns.actions";
import {
  showBuildCampaign,
  showCampaignDetailPopup,
  showConfirmPopup,
} from "store/actions/popup.actions";
import { CampaignStatus, CAMPAIGN_STATUS_TEXT } from "store/states/campaign.state";
import { PaymentStatus } from "store/states/payments.state";
import { CampaignItemListStyled, RedirectCampaign } from "./styled";

interface ICampaignItemListProps {
  name: string;
  period: string;
  budget: number;
  paymentStatus: PaymentStatus | null;
  campaignStatus: CampaignStatus;
  id: string;
}

const CampaignItemList: React.FunctionComponent<ICampaignItemListProps> = ({
  name,
  period,
  budget,
  paymentStatus,
  campaignStatus,
  id,
}) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <CampaignItemListStyled $open={open} $loading={false}>
      <div
        className="list-header"
        onClick={() => {
          if (!open) {
            //
          }
          setOpen((prev) => !prev);
        }}
      >
        <div className="list-name">
          {open ? <FolderUpIcon /> : <FolderDownIcon />}
          <span className="name">{name}</span>
        </div>

        {!open && (
          <div className="campaign-status">
            <div className={`indicator ${CAMPAIGN_STATUS_TEXT[campaignStatus]}`} />
            <span className="status-text">{CAMPAIGN_STATUS_TEXT[campaignStatus]}</span>
          </div>
        )}
      </div>

      <div className="list-wrapper">
        <div className="list-body">
          <div className="campaign-info-field">
            <h6 className="info-title">Campaign Period</h6>
            <span>{period}</span>
          </div>

          <div className="campaign-info-field">
            <h6 className="info-title">Budget</h6>
            <span>{budget ? `$${budget}` : "-"}</span>
          </div>

          <div className="campaign-info-field">
            <h6 className="info-title">Status</h6>
            <div className="campaign-status">
              <div className={`indicator ${CAMPAIGN_STATUS_TEXT[campaignStatus]}`} />
              <span>{CAMPAIGN_STATUS_TEXT[campaignStatus]}</span>
            </div>
          </div>

          <div style={{ marginTop: "0px" }}>
            {paymentStatus ? (
              <RedirectCampaign
                onClick={() => {
                  dispatch(showCampaignDetailPopup(id));
                }}
              >
                <span>Manage Campaigns</span>
              </RedirectCampaign>
            ) : campaignStatus === CAMPAIGN_STATUS_TEXT.Draft ? (
              <div className="unfinish-campaign">
                {/* <Button>Edit</Button> */}
                <Button
                  onClick={() => {
                    dispatch(showBuildCampaign(id));
                  }}
                  squared
                  noPadding
                  height={20}
                  color="blue"
                >
                  Continue
                </Button>

                <Button
                  squared
                  noPadding
                  height={20}
                  transparent
                  onClick={() =>
                    dispatch(
                      showConfirmPopup({
                        title: "Delete Campaign",
                        body: "Are you sure you want to delete this campaign?",
                        confirmText: "Delete",
                        action: () => {
                          dispatch(deleteHomeCampaign([id]));
                        },
                      })
                    )
                  }
                >
                  <TrashIcon width={10} height={10} />
                  <span>Delete</span>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </CampaignItemListStyled>
  );
};

export default CampaignItemList;
