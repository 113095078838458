import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import * as actions from "../actions/save-keywords-list.actions";
import * as types from "../action-types/saved-keywords-list.types";
import api from "../../services/api";
import { handlingError } from "../../constants/utility/error";

function* onGetSavedKeywordsList() {
  try {
    yield put(actions.getSavedKeywordsListRequest());
    const { data } = yield call(api.savedKeywordsList.getSavedKeywordsList);
    yield put(actions.getSavedKeywordsListSuccess(data));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getSavedKeywordsListFailure(error.response.data.message));
  }
}

function* watchOnGetSavedKeywordsList() {
  yield takeEvery(types.GET_SAVED_KEYWORDS_LIST, onGetSavedKeywordsList);
}

export default function* saveKeywordsListSaga() {
  yield all([fork(watchOnGetSavedKeywordsList)]);
}
