import { Formik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Select from "components/DropdownSelect";
import SearchIcon from "components/Icons/SearchIcon";
import { UserAppHeaderStyled } from "./styled";
import headerSearchBarSchema from "schemas/header-search-bar.schema";

interface IHeaderProps {
  onSearchFormSubmit: (keyword: string) => void;
  searchFieldDisabled?: boolean;
  searchField?: "input";
  searchFieldPlaceholder?: string;
  textForSearchField?: string;
  disabledMessage?: string;
  children?: (seachTerms: string, resetForm: () => void) => React.ReactNode | JSX.Element[] | null;
  validateUrl?: boolean;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  onSearchFormSubmit,
  searchFieldDisabled,
  searchField,
  searchFieldPlaceholder,
  textForSearchField,
  disabledMessage,
  validateUrl,
  children,
}) => {
  const onFormSubmit = ({ keyword }: { keyword: string }) => {
    onSearchFormSubmit(keyword);
  };

  return (
    <Formik
      validationSchema={validateUrl && headerSearchBarSchema}
      onSubmit={(values, { resetForm }) => {
        onFormSubmit(values);
        resetForm();
      }}
      initialValues={{
        keyword: "",
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => {
        return (
          <UserAppHeaderStyled
            className="input-header"
            error={!!(touched.keyword && errors.keyword)}
            disabled={searchFieldDisabled}
          >
            <div className="form-wrapper">
              <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="searchGroup m-0">
                  {!searchFieldDisabled && <SearchIcon className="icon left" />}
                  {searchField === "input" ? (
                    <Form.Control
                      onChange={handleChange}
                      className="keyword-search-bar"
                      type="text"
                      placeholder={!searchFieldDisabled ? searchFieldPlaceholder : ""}
                      name="keyword"
                      disabled={searchFieldDisabled}
                      value={values.keyword}
                    />
                  ) : (
                    <Select
                      options={[]}
                      create
                      multi
                      dropdownHandle={false}
                      noDataRenderer={() => null}
                      dropdownHandleRenderer={() => null}
                      placeholder="Search Keywords..."
                    />
                  )}
                  {searchFieldDisabled && (
                    <span className="disable-message">{disabledMessage}</span>
                  )}
                </div>
              </Form>
              {touched.keyword && errors.keyword && (
                <span className="url-field-error">{errors.keyword}</span>
              )}
            </div>
            {textForSearchField ? <p className="traffic-info">{textForSearchField}</p> : null}
            {children && children(values.keyword, resetForm)}
          </UserAppHeaderStyled>
        );
      }}
    </Formik>
  );
};

Header.defaultProps = {
  searchFieldDisabled: false,
};

export default Header;
