import { put, call, takeEvery, all, fork, select, take, takeLatest } from "redux-saga/effects";
import api from "../../services/api";
import * as types from "store/action-types/targeting.types";
import * as actions from "store/actions/targeting.actions";
import { handlingError } from "../../constants/utility/error";

function* onGetTargetingList(action: types.GetTargetingListAction) {
  try {
    yield put(actions.getTargetingRequest());

    const [{ data: browsers }, { data: os }, { data: devices }, { data: countries }] = yield all([
      call(api.targeting.getTargeting, "browser"),
      call(api.targeting.getTargeting, "os"),
      call(api.targeting.getTargeting, "device"),
      call(api.targeting.getCountries),
    ]);

    yield put(
      actions.getTargetingSuccess({
        browsers,
        os,
        devices,
        countries,
      })
    );
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getTargetingFailure(error.response.data.message));
  }
}

function* watchOnGetTargetingList() {
  yield takeLatest(types.GET_TARGETING, onGetTargetingList);
}

export default function* TargetingSaga() {
  yield all([fork(watchOnGetTargetingList)]);
}
