import styled from "styled-components/macro";

export const SuggestedKeywordsPage = styled.div`
  margin-top: 20px;

  .keyword-tool-title {
    font-size: 11px;
  }

  .page-title {
    font-size: 20px;
    font-weight: bold;
  }

  .suggest-location-filter {
    display: flex;
    justify-content: flex-end;
  }

  .suggested-keywords-wrapper {
    margin: 20px 0;
  }

  .selectedKeywords {
    margin-right: 0;
    margin-left: 5px;

    .keyword {
      color: ${({ theme }) => theme.color.primary};
      margin-top: 10px;

      &:before {
        display: none;
      }
    }
  }

  .actions {
    margin-left: 15px;
    margin-right: 0;

    .dropdown,
    .exportBtn {
      flex: 1;
      flex-basis: 50%;
    }
  }

  .filters {
    display: flex;
    justify-content: space-between;

    .filters-action {
      display: flex;
    }

    .field {
      .actions {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .exportKeywordsPopupWrapper {
    position: absolute;
    margin: auto;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .campaignsMenu {
    max-width: 200px;

    .dropdown-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 767px) {
    .actions {
      margin-left: 0;
    }
  }
`;
