import styled from "styled-components";

export const RedirectCampaign = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.blue};
  font-size: 10px;
  padding: 5px 10px;
  width: fit-content;
  color: #ffffff;
  transition: 0.1s;
`;

export const CampaignItemListStyled = styled.div<{ $open: boolean; $loading: boolean }>`
  background: ${({ theme }) => theme.inputBorder};
  transition: 0.1s;
  border-radius: 10px;
  color: ${({ theme }) => theme.contrast};
  font-size: 10px;
  cursor: pointer;

  .campaign-status {
    display: flex;
    align-items: center;
    gap: 3px;

    .status-text {
      width: 28px;
      font-style: italic;
      font-size: 8px;
    }
  }

  .Pending,
  .Paused {
    background-color: ${({ theme }) => theme.secondary300};
  }

  .Draft {
    background-color: ${({ theme }) => theme.color.primary};
  }

  .Active {
    background-color: ${({ theme }) => theme.secondary900};
  }

  .Inactive,
  .Deleted {
    background-color: ${({ theme }) => theme.color.gray};
  }

  .indicator {
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  .unfinish-campaign {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .icon-svg {
        stroke: ${({ theme }) => theme.contrast};
      }
    }
  }

  .list-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 8px ${({ $open }) => ($open ? "5px" : "8px")} 15px;

    .list-name {
      display: flex;

      > svg {
        width: 15px;
        height: 15px;

        .icon-svg {
          transition: 0.1s;
          stroke: ${({ theme }) => theme.contrast};
        }
      }

      .name {
        font-size: 12px;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 10px;
      }
    }
  }

  .list-wrapper {
    overflow: hidden;
    transition: 0.3s;
    height: ${({ $open }) => ($open ? 175 : 0)}px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;

    .empty {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .list-body {
      padding: 0px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .campaign-info-field {
        margin-bottom: 10px;

        .info-title {
          transition: 0.1s;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 0px;
          color: rgb(181, 181, 181);
        }
      }
    }
  }
`;
