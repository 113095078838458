import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as types from "../action-types/competitor.types";
import * as actions from "../actions/competitor.actions";
import api from "../../services/api";
import { handlingError } from "../../constants/utility/error";

// save

function* onSaveCompetitor(action: types.SaveCompetitorAction) {
  try {
    yield put(actions.saveCompetitorRequest());
    const { competitor } = yield select();
    if (competitor.data.length > 4) {
      yield call(api.competitor.deleteCompetitorHistory, [
        competitor.data[competitor.data.length - 1].id,
      ]);
    }
    const { data } = yield call(api.competitor.saveCompetitor, action.payload);
    yield put(actions.saveCompetitorSuccess(data[0]));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.saveCompetitorFailure(error.response.data.message));
  }
}

function* watchonSaveCompetitor() {
  yield takeEvery(types.SAVE_COMPETITOR, onSaveCompetitor);
}

// get

function* onLoadCompetitor(action: types.GetCompetitorAction) {
  try {
    yield put(actions.getCompetitorRequest());
    const { data } = yield call(api.competitor.getCompetitorHistory);
    yield put(actions.getCompetitorSuccess(data));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getCompetitorFailure(error.response.data.message));
  }
}

function* watchonLoadCompetitor() {
  yield takeEvery(types.GET_COMPETITOR, onLoadCompetitor);
}

// delete

function* onDeleteCompetitor(action: types.DeleteCompetitorAction) {
  try {
    yield put(actions.deleteCompetitorRequest());
    const { data } = yield call(api.competitor.deleteCompetitorHistory, action.payload);
    yield put(actions.deleteCompetitorSuccess(data.deletedIds));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.deleteCompetitorFailure(error.response.data.message));
  }
}

function* watchonDeleteCompetitor() {
  yield takeEvery(types.DELETE_COMPETITOR, onDeleteCompetitor);
}

export default function* CompetitorSaga() {
  yield all([
    fork(watchonSaveCompetitor),
    fork(watchonLoadCompetitor),
    fork(watchonDeleteCompetitor),
  ]);
}
