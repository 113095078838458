import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "components/Checkbox";
import { AdsIcon, TrashIcon } from "components/Icons";
import GoogleIcon from "components/Icons/GoogleIcon";
import React from "react";
import { ISavedKeyword } from "store/states/save-keywords-list.state";
import { KeywordListItemStyled, SaveListButton } from "./styled";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { getCurrentSavedKeywordsList } from "store/actions/current-saved-keywords-list.actions";
import Spinner from "components/Spinner";
import { deleteKeywords, deleteKeywordsList } from "store/actions/keyword-list.actions";
import { RootState } from "store/store";
import { addKeywords } from "store/actions/keyword-list-form.actions";
import {
  showConfirmPopup,
  showImportKeywords,
  showKeywordListPopup,
} from "store/actions/popup.actions";
import SaveToListIcon from "components/Icons/SaveToListIcon";
import FolderUpIcon from "components/Icons/FolderUpIcon";
import FolderDownIcon from "components/Icons/FolderDownIcon";
import FirstCharacterLogo from "components/Icons/FirstCharacterLogo/indx";
import Button from "components/Button";

// const mapStateToProps = ({ keywordsList }: RootState) => ({
//   keywordsList,
// });

// const connector = connect(mapStateToProps);

// type PropsFromRedux = ConnectedProps<typeof connector>;

// interface IKeywordListItem extends PropsFromRedux {
interface IKeywordListItem {
  name: string;
  keywords: Array<ISavedKeyword>;
  id: string;
  loading: boolean;
}

const KeywordListItem: React.FunctionComponent<IKeywordListItem> = ({
  id,
  name,
  keywords,
  loading,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedKeywords, setSelectedKeywords] = React.useState<Array<string>>([]);
  const [selectedIds, setSelectedIds] = React.useState<Array<string>>([]);
  const dispatch = useDispatch();

  const onDeleteKeywords = async () => {
    dispatch(
      showConfirmPopup({
        title: "Delete?",
        body: "Are you sure you want to delete selected keywords?",
        confirmText: "Delete",
        action: () => {
          dispatch(deleteKeywords(selectedIds, id));
          setSelectedKeywords([]);
          setSelectedIds([]);
          // dispatch(getCurrentSavedKeywordsList(id));
        },
      })
    );
  };

  const onDeleteKeywordsList = async () => {
    dispatch(
      showConfirmPopup({
        title: "Delete?",
        body: "Are you sure you want to delete this list?",
        confirmText: "Delete",
        action: () => dispatch(deleteKeywordsList([id])),
      })
    );
  };

  return (
    <KeywordListItemStyled data-testid="keyword-list-item" $open={open} $loading={loading}>
      <div
        onClick={() => {
          if (!open && keywords.length) {
            dispatch(getCurrentSavedKeywordsList(id));
          }
          setOpen((prev) => !prev);
        }}
        className="list-header"
      >
        <div className="list-name">
          {open ? <FolderUpIcon /> : <FolderDownIcon />}
          <span className="name">{name}</span>
        </div>
        {open ? (
          <span
            onClick={(e) => {
              e.stopPropagation();
              onDeleteKeywordsList();
            }}
            className="delete-list"
          >
            <TrashIcon />
            <span>Delete list</span>
          </span>
        ) : (
          <span
            onClick={(e) => {
              e.stopPropagation();
              onDeleteKeywordsList();
            }}
            className="delete-icon"
          >
            <TrashIcon />
          </span>
        )}
      </div>
      <div className="list-wrapper">
        {!loading && keywords.length ? (
          <div className="list-body">
            <div className="table-header">
              <span className="checkbox-wrapper">
                <Checkbox
                  checked={selectedKeywords.length === keywords.length}
                  onSelect={() => {
                    setSelectedKeywords((prev) =>
                      prev.length === keywords.length
                        ? []
                        : keywords.map((keyword) => keyword.keyword)
                    );

                    setSelectedIds((prev) =>
                      prev.length === keywords.length ? [] : keywords.map((keyword) => keyword.id)
                    );
                  }}
                  size="sm"
                />
              </span>
              <span>Keywords</span>
              <span>Monthly Searches</span>
              <span>Network Matches</span>
              <span>Scores</span>
              <span className="icon-wrapper">
                <GoogleIcon className="icon" />
                <span>CPC</span>
              </span>
              <span className="icon-wrapper">
                <FirstCharacterLogo className="icon" />
                <span>CPC</span>
              </span>
            </div>
            <div className="table-content">
              {keywords.map((keyword) => {
                return (
                  <div key={keyword.id} className="keyword-data-row">
                    <span className="checkbox-wrapper">
                      <Checkbox
                        checked={selectedKeywords.includes(keyword.keyword)}
                        onSelect={() => {
                          setSelectedKeywords((prev) =>
                            prev.includes(keyword.keyword)
                              ? prev.filter((currentKeyword) => currentKeyword !== keyword.keyword)
                              : [...prev, keyword.keyword]
                          );

                          setSelectedIds((prev) =>
                            prev.includes(keyword.id)
                              ? prev.filter((currentKeyword) => currentKeyword !== keyword.id)
                              : [...prev, keyword.id]
                          );
                        }}
                        size="sm"
                      />
                    </span>
                    <span>{keyword.keyword}</span>
                    <span className="monthly-searches">{keyword.stats?.svFormatted || ""}</span>
                    <span className="network-matches">
                      {keyword.stats?.radSvFormatted ? `+${keyword.stats.radSvFormatted}` : ""}
                    </span>
                    <span className="score">{keyword.stats?.competition || ""}</span>
                    <span>
                      {keyword.stats?.googleCpcString ? `$${keyword.stats.googleCpcString}` : ""}
                    </span>
                    <span className="rad-cpc">
                      {keyword.stats?.radCpcString ? `$${keyword.stats.radCpcString}` : ""}
                    </span>
                  </div>
                );
              })}
            </div>

            <div
              // onClick={() => dispatch(showKeywordListPopup({ values: selectedKeywords }))}
              className="table-actions"
            >
              <SaveListButton
                onClick={() => dispatch(showKeywordListPopup({ values: selectedKeywords }))}
                disabled={!selectedKeywords.length}
              >
                <SaveToListIcon />
                <span>Save to list</span>
              </SaveListButton>

              <Button
                className="delete-keywords"
                noPadding
                transparent
                height={20}
                disabled={!selectedKeywords.length}
                onClick={() => onDeleteKeywords()}
              >
                <TrashIcon width={10} height={10} />

                <span>Remove from list</span>
              </Button>
            </div>
          </div>
        ) : loading ? (
          <Spinner size="sm" />
        ) : (
          <div className="empty">
            <span>The list is empty.</span>
            <span>Please add any of keywords.</span>
          </div>
        )}
      </div>
    </KeywordListItemStyled>
  );
};

// export default connector(KeywordListItem);
export default KeywordListItem;
