import { put, call, takeEvery, all, fork, takeLatest, delay } from "redux-saga/effects";
import * as types from "../action-types/campaigns.types";

import api from "../../services/api";
import { ICampaign, ICampaignListData } from "../states/campaigns.state";
import { closeSlideModal } from "../actions/sidebar.actions";
import { hideNewCampaignPopup } from "../actions/popup.actions";
import { ICampaignFilters, IErrorMessage } from "../../services/types";
import { handlingError } from "../../constants/utility/error";
import * as actions from "store/actions/campaigns.actions";

// get paid campaigns

function* onLoadCampaigns(action: types.GetCampaignsAction) {
  try {
    yield put(actions.getCampaignsRequest(action.filters));
    const { data, total } = yield call(api.campaigns.getCampaigns, action.filters);
    yield put(actions.getCampaignsSuccess(data, total));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getCampaignsFailure(error));
  }
}

function* watchonLoadCampaigns() {
  yield takeLatest(types.GET_CAMPAIGNS, onLoadCampaigns);
}

export default function* campaignsSaga() {
  yield all([fork(watchonLoadCampaigns)]);
}
