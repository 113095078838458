import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Dropdown, Modal } from "react-bootstrap";
import {
  hideComparePopup,
  hideCompetitorPopup,
  hideKeywordSavedListPopup,
  hideSuggestedPopup,
} from "store/actions/popup.actions";
import { KeywordListPopupModal } from "./styled";
import { createKeywordsList, fetchSavedKeywordList } from "store/actions/keyword-list.actions";
import Button from "../Button";
import { RootState } from "store/store";
import { Formik, FormikErrors, useFormik } from "formik";
import newKeywordListSchema from "schemas/new-keyword-list.schema";
import Input from "components/Input";
import CloseButton from "components/CloseButton";
import DropdownButton from "components/DropdownButton";
import { addKeywords } from "store/actions/keyword-list-form.actions";

interface IKeywordListPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup, keywordListForm, keywordsList }: RootState) => ({
  popup,
  keywordsList,
  keywordListForm,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const KeywordListPopup: React.FunctionComponent<IKeywordListPopupProps> = ({
  popup,
  keywordsList,
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const dispatch = useDispatch();
  const [keywordList, selectKeywordList] = React.useState<null | { id: string; name: string }>(
    null
  );

  const onCancelButtonClick = () => {
    dispatch(hideKeywordSavedListPopup());
  };

  const onAdd = () => {
    if (keywordList && popup.values.keywords && popup.values.keywords.length) {
      dispatch(addKeywords(popup.values.keywords, keywordList.id));
    }
  };

  React.useEffect(() => {
    if (popup.showKeywordListPopup) {
      dispatch(fetchSavedKeywordList(0));
      return () => {
        onCancelButtonClick();
      };
    }
  }, [popup.showKeywordListPopup]);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik({
    validationSchema: newKeywordListSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (keywordList) {
        onAdd();
      } else {
        onCreate(values);
      }
      dispatch(hideKeywordSavedListPopup());
      setSubmitting(false);
    },
    initialValues: { name: "" },
  });

  const onCreate = (data: { name: string }) => {
    const existing = keywordsList.data.find(
      (item) => item.name.toLowerCase() === values.name.toLowerCase()
    );
    if (existing && popup.values.keywords) {
      // dispatch(addKeywords(values.keywords, existing.id));
      dispatch(addKeywords(popup.values.keywords, existing.id));
    } else {
      dispatch(createKeywordsList(data.name, popup.values.keywords, { setErrors }));
    }
  };

  const onExit = () => {
    selectKeywordList(null);
    setShowDropdown(false);
    // dispatch(setKeywordListFormData(keywordListFormDefaultState));
    resetForm();
  };

  return (
    <KeywordListPopupModal
      centered
      show={popup.showKeywordListPopup}
      backdrop="static"
      onExited={onExit}
    >
      <Modal.Header>
        <Modal.Title>Manage Keywords List</Modal.Title>
        <CloseButton
          top={10}
          right={34}
          dark
          onClick={() => dispatch(hideKeywordSavedListPopup())}
        />
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="keyword-list-label">List name</label>
            <div className="input-wrapper">
              <Input
                black
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  if (keywordList) {
                    selectKeywordList(null);
                  }
                }}
                type="text"
                placeholder="Eg. Keyword List 1"
                name="name"
                value={values.name}
                autoComplete="off"
              />
              <div className="select" onClick={() => setShowDropdown((prev) => !prev)} />
              {showDropdown && (
                <ul className="keyword-list-selector">
                  {keywordsList.data.map((list) => {
                    return (
                      <li
                        key={list.id}
                        onClick={() => {
                          setShowDropdown((prev) => !prev);
                          selectKeywordList(list);
                          setFieldValue("name", list.name);
                        }}
                        className="keyword-list-item"
                      >
                        {list.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>

            {errors.name && <div className="errorMessage">{errors.name}</div>}
          </div>
          <div className="footer-bar">
            <div className="action-buttons">
              <Button width={125} color="gray" type="button" onClick={onCancelButtonClick}>
                Cancel
              </Button>
              <Button width={125} type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Please wait..." : "Save"}
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </KeywordListPopupModal>
  );
};

export default connector(KeywordListPopup);
