import { all, fork } from "redux-saga/effects";
import CampaignsSaga from "./campaigns.saga";
import AdminSaga from "./admin.saga";
import UsersSaga from "./users.saga";
import SaveKeywordsListSaga from "./save-keywords-list.saga";
import AdminNewsFeedSaga from "./admin-news-feed-saga";
import FeedsSaga from "./feed.saga";
import CampaignSaga from "./campaign.saga";
import CreativeSaga from "./creative.saga";
import BannersSaga from "./banner.saga";
import CurrentSavedKeywordsListSaga from "./current-saved-list.saga";
import PaymentsSaga from "./payments.saga";
import HomeSaga from "./home.saga";
import CampaignPaymentSaga from "./campaign-payment.saga";
import CompareSaga from "./compare.saga";
import SuggestedSaga from "./suggested.saga";
import CompetitorSaga from "./competitor.saga";
import UserSaga from "./user.saga";
import TargetingSaga from "./targeting.saga";

export default function* rootSaga() {
  yield all([
    fork(CampaignsSaga),
    fork(AdminSaga),
    fork(UsersSaga),
    fork(SaveKeywordsListSaga),
    fork(AdminNewsFeedSaga),
    fork(FeedsSaga),
    fork(CampaignSaga),
    fork(CreativeSaga),
    fork(BannersSaga),
    fork(CurrentSavedKeywordsListSaga),
    fork(PaymentsSaga),
    fork(HomeSaga),
    fork(CampaignPaymentSaga),
    fork(CompareSaga),
    fork(SuggestedSaga),
    fork(CompetitorSaga),
    fork(UserSaga),
    fork(TargetingSaga),
  ]);
}
