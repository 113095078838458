import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as types from "../action-types/compare.types";
import * as actions from "../actions/compare.actions";
import api from "../../services/api";
import { handlingError } from "../../constants/utility/error";
import { ISavedKeyword } from "store/states/keywords-list.state";

// save

function* onSaveCompare(action: types.SaveCompareAction) {
  try {
    yield put(actions.saveCompareRequest());
    const { compare } = yield select();
    if (compare.data.length > 4) {
      yield put(actions.deleteCompare([compare.data[compare.data.length - 1].id]));
    }
    const { data } = yield call(api.compare.saveCompare, action.payload);
    yield put(
      actions.saveCompareSuccess({
        id: data.keywordGroup.id,
        keywords: data.keywords,
      })
    );
  } catch (error: any) {
    handlingError(error);
    yield put(actions.saveCompareFailure(error.response.data.message));
  }
}

function* watchonSaveCompare() {
  yield takeEvery(types.SAVE_COMPARE, onSaveCompare);
}

// get

function* onLoadCompare(action: types.GetCompareAction) {
  try {
    yield put(actions.getCompareRequest());
    const { data } = yield call(api.compare.getCompareHistory);
    yield put(actions.getCompareSuccess(data));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getCompareFailure(error.response.data.message));
  }
}

function* watchonLoadCompare() {
  yield takeEvery(types.GET_COMPARE, onLoadCompare);
}

// delete

function* onDeleteCompare(action: types.DeleteCompareAction) {
  try {
    yield put(actions.deleteCompareRequest());
    const { data } = yield call(api.compare.deleteCompareHistory, action.payload);
    yield put(actions.deleteCompareSuccess(data.deletedIds));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.deleteCompareFailure(error.response.data.message));
  }
}

function* watchonDeleteCompare() {
  yield takeEvery(types.DELETE_COMPARE, onDeleteCompare);
}

export default function* compareSaga() {
  yield all([fork(watchonSaveCompare), fork(watchonLoadCompare), fork(watchonDeleteCompare)]);
}
