import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { userAppRoutes } from "./routes";
import AdvertiserPopup from "components/AdvertiserPopup";
import KeywordListPopup from "components/KeywordListPopup";
import { fetchContentCategories } from "store/actions/categories.actions";
import { RootState } from "store/store";
import NewCampaignPopup from "components/NewCampaignPopup";
import { getSavedKeywordsList } from "store/actions/save-keywords-list.actions";
import ImportKeywordsForm from "../Forms/ImportKeywordsForm";
import ComparePopup from "components/ComparePopup";
import BuildCampaign from "components/BuildCampaign";
import CompetitorPopup from "components/CompetitorPopup";
import SuggestedPopup from "components/SuggestedPopup";
import * as AUTH_ROUTES from "modules/Auth/routes";
import BetaAlert from "components/BetaAlert";
import { fetchSavedKeywordList } from "store/actions/keyword-list.actions";
import { getTargeting } from "store/actions/targeting.actions";

interface IClientAppProps extends PropsFromRedux {
  theme: "light" | "dark";
}

const mapStateToProps = ({ user, popup }: RootState) => ({
  user,
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ClientApp: React.FunctionComponent<IClientAppProps> = ({ user, popup, theme }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (user.accessToken) {
      dispatch(fetchContentCategories());
      dispatch(fetchSavedKeywordList(0));
      dispatch(getSavedKeywordsList());
      dispatch(getTargeting());
    }
  }, [user.accessToken]);

  return (
    <React.Fragment>
      <Routes>
        {user.accessToken ? (
          <React.Fragment>
            {userAppRoutes.map(({ path, component: Component }) => {
              return <Route key={path} path={path} element={<Component />} />;
            })}
          </React.Fragment>
        ) : (
          <Route
            path="*"
            element={<Navigate to={`${AUTH_ROUTES.AUTH_BASE_ROUTE}/${AUTH_ROUTES.LOGIN}`} />}
          />
        )}

        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>

      {/* <IntroPopup
        showPopup={popup.showIntroPopup}
        onSkipButtonClick={() => {
          dispatch(hideIntroPopup());
        }}
      /> */}

      <AdvertiserPopup />
      <BetaAlert theme={theme} />
      <BuildCampaign />
      <CompetitorPopup />
      <SuggestedPopup />
      <ComparePopup />
      <KeywordListPopup />
      <NewCampaignPopup />
      <ImportKeywordsForm />
    </React.Fragment>
  );
};

export default connector(ClientApp);
