import { put, call, takeEvery, all, fork, select, take } from "redux-saga/effects";
import * as types from "../action-types/suggested.types";
import * as actions from "../actions/suggested.actions";
import api from "../../services/api";
import { handlingError } from "../../constants/utility/error";
// save

function* onSaveSuggested(action: types.SaveSuggestedAction) {
  try {
    yield put(actions.saveSuggestedRequest());
    const { suggested } = yield select();
    if (suggested.data.length > 4) {
      yield call(api.suggested.deleteSuggestedHistory, [
        suggested.data[suggested.data.length - 1].id,
      ]);
    }
    const { data } = yield call(api.suggested.saveSuggested, action.payload);
    yield put(actions.saveSuggestedSuccess(data[0]));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.saveSuggestedFailure(error.response.data.message));
  }
}

function* watchonSaveSuggested() {
  yield takeEvery(types.SAVE_SUGGESTED, onSaveSuggested);
}

// get

function* onLoadSuggested(action: types.GetSuggestedAction) {
  try {
    yield put(actions.getSuggestedRequest());
    const { data } = yield call(api.suggested.getSuggestedHistory);
    yield put(actions.getSuggestedSuccess(data));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getSuggestedFailure(error.response.data.message));
  }
}

function* watchonLoadSuggested() {
  yield takeEvery(types.GET_SUGGESTED, onLoadSuggested);
}

// delete

function* onDeleteSuggested(action: types.DeleteSuggestedAction) {
  try {
    yield put(actions.deleteSuggestedRequest());
    const { data } = yield call(api.suggested.deleteSuggestedHistory, action.payload);
    yield put(actions.deleteSuggestedSuccess(data.deletedIds));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.deleteSuggestedFailure(error.response.data.message));
  }
}

function* watchonDeleteSuggested() {
  yield takeEvery(types.DELETE_SUGGESTED, onDeleteSuggested);
}

export default function* SuggestedSaga() {
  yield all([fork(watchonSaveSuggested), fork(watchonLoadSuggested), fork(watchonDeleteSuggested)]);
}
