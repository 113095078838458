import * as Yup from "yup";
import { regex } from "constants/utility/campaign";

export default Yup.object().shape({
  keyword: Yup.string()
    .matches(regex, "*Enter correct url")
    .min(2, "Website URL must have at least 2 characters")
    .max(100, "Website URL can't be longer than 100 characters")
    .required("Website URL is required"),
});
