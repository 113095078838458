import { showBetaAlert, showIntroPopup } from "./popup.actions";
import { axiosForReverseAds } from "../../axiosConfig";
import { NavigateFunction } from "react-router-dom";

import { AnyAction } from "redux";
import { RootState } from "../store";
import { ThunkAction } from "redux-thunk";
import { FormikErrors } from "formik";
import IAuthMeResponse from "../../models/auth-me-response.interface";
import ILoginResponse from "../../models/login-response.interface";
import IRegisterResponse from "../../models/register-response.interface";
import { handlingError, onSuccess } from "constants/utility/error";

import * as types from "../action-types/user.types";
import * as AUTH_ROUTE from "modules/Auth/routes";
import { IErrorMessage } from "services/types";
import { ENUM_USER_ROLES, USER_ROLE } from "store/states/user.state";
import { getCountries } from "./location.actions";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const RESET_USER_DETAILS = "RESET_USER_DETAILS";
export const USER_LOGOUT = "USER_LOGOUT";

export interface IUpdateUserData {
  email?: string;
  userName?: string;
  fullName?: string;
  country?: string;
  role?: USER_ROLE;
  accessToken?: string;
  id?: string;
  website?: string;
  businessId?: number;
  isAuthenticated: boolean;
}

export interface IRegister {
  email: string;
  password: string;
  fullName: string;
  // website: string;
  // businessId: number;
  // country: string;
  subscribeSpecialOffer: boolean;
}

export const setUserDetails = (payload: IUpdateUserData) => {
  return {
    type: SET_USER_DETAILS,
    payload: payload,
  };
};

export const resetUserDetails = () => {
  return {
    type: RESET_USER_DETAILS,
  };
};

export const registerUser = (
  formData: IRegister,
  setErrors: (
    errors: FormikErrors<{
      name: string;
      email: string;
      password: string;
      confirmPassword: string;
    }>
  ) => void,
  navigate: NavigateFunction
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      await axiosForReverseAds.post<IRegisterResponse>("auth/v2/register", {
        email: formData.email,
        password: formData.password,
        fullName: formData.fullName,
        // country: formData.country,
        // businessId: formData.businessId,
        // website: formData.website,

        // subscribeSpecialOffer: formData.subscribeSpecialOffer,
      });

      // dispatch(
      //   loginUser(
      //     {
      //       email: formData.email,
      //       password: formData.password,
      //     },
      //     navigate
      //   )
      // );

      navigate("/auth/register-success");

      onSuccess("Your account has been created");

      dispatch(showIntroPopup());
    } catch (error: any) {
      if (error.response.data?.formErrors && Object.keys(error.response.data.formErrors).length) {
        setErrors({ ...error.response.data.formErrors });
      } else {
        handlingError(error);
      }
    }
  };
};

export const loginUser = (
  userDetails: {
    email: string;
    password: string;
  },
  navigate: NavigateFunction
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    const { data } = await axiosForReverseAds.post<ILoginResponse>("auth/v2/login", {
      email: userDetails.email,
      password: userDetails.password,
    });
    dispatch(getCountries());

    window.localStorage.setItem("accessToken", data.data.accessToken);

    dispatch(showBetaAlert());

    const response = await axiosForReverseAds.post<IAuthMeResponse>("auth/v2/me");
    const accountInfo = response.data.data;
    dispatch(
      setUserDetails({
        userName: accountInfo.userName,
        email: accountInfo.email,
        country: accountInfo.country,
        role: accountInfo.role,
        website: accountInfo.website,
        businessId: accountInfo.businessId,
        // isSubscribed        : accountInfo.isSubscribed,
        accessToken: data.data.accessToken,
        isAuthenticated: true,
        id: accountInfo.id,
      })
    );
    onSuccess("You have logged in");
    if (accountInfo.role !== ENUM_USER_ROLES.USER) {
      navigate("/admin/campaigns/");
    } else {
      navigate("/app/home");
    }
  };
};

export const logoutUser = (
  navigate: NavigateFunction
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGOUT,
      });
      window.localStorage.removeItem("accessToken");
      navigate(`${AUTH_ROUTE.AUTH_BASE_ROUTE}/${AUTH_ROUTE.LOGIN}`);
    } catch (error: any) {
      //
    }
  };
};

// update advertiser

export function updateAdvertiser(payload: {
  businessId: number;
  website: string;
}): types.UpdateAdvertiserAction {
  return {
    type: types.UPDATE_ADVERTISER,
    payload,
  };
}

export function updateAdvertiserRequest(): types.UpdateAdvertiserRequestAction {
  return {
    type: types.UPDATE_ADVERTISER_REQUEST,
  };
}

export function updateAdvertiserSuccess(payload: {
  businessId: number;
  website: string;
}): types.UpdateAdvertiserSuccessAction {
  return {
    type: types.UPDATE_ADVERTISER_SUCCESS,
    payload,
  };
}

export function updateAdvertiserFailure(error: string): types.UpdateAdvertiserFailureAction {
  return {
    type: types.UPDATE_ADVERTISER_FAILURE,
    error,
  };
}

// update password

export function updatePassword(payload: {
  password: string;
  newPassword: string;
}): types.UpdatePasswordAction {
  return {
    type: types.UPDATE_PASSWORD,
    payload,
  };
}

export function updatePasswordRequest(): types.UpdatePasswordRequestAction {
  return {
    type: types.UPDATE_PASSWORD_REQUEST,
  };
}

export function updatePasswordSuccess(): types.UpdatePasswordSuccessAction {
  return {
    type: types.UPDATE_PASSWORD_SUCCESS,
  };
}

export function updatePasswordFailure(error: {
  newPassword: Array<string>;
}): types.UpdatePasswordFailureAction {
  return {
    type: types.UPDATE_PASSWORD_FAILURE,
    error,
  };
}

// forget password

export function onForgetPassword(payload: string): types.ForgetPasswordAction {
  return {
    type: types.FORGET_PASSWORD,
    payload,
  };
}

export function onForgetPasswordRequest(): types.ForgetPasswordRequestAction {
  return {
    type: types.FORGET_PASSWORD_REQUEST,
  };
}

export function onForgetPasswordSuccess(): types.ForgetPasswordSuccessAction {
  return {
    type: types.FORGET_PASSWORD_SUCCESS,
  };
}

export function onForgetPasswordFailure(error: string): types.ForgetPasswordFailureAction {
  return {
    type: types.FORGET_PASSWORD_FAILURE,
    error,
  };
}
