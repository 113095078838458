import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { axiosForReverseAds } from "axiosConfig";
import { setUserDetails } from "store/actions/user.actions";
import * as AUTH_ROUTES from "modules/Auth/routes";
import * as CLIENT_APP_ROUTES from "modules/Client/routes";
import * as ADMIN_APP_ROUTES from "modules/Admin/routes";
import * as USER_SETTING_ROUTES from "modules/User/routes";
import IndexNavigation from "modules";
import AuthModule from "modules/Auth";
import AuthWrapper from "layout/AuthWrapper";
import ClientAppModule from "modules/Client";
import AdminAppModule from "modules/Admin";
import UserSettingModule from "modules/User";
import { GlobalStyle } from "./globalStyle";
import defaultTheme, { darkTheme, lightTheme } from "./theme";
import { getCountries } from "store/actions/location.actions";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import PageNotFound from "modules/Client/pages/PageNotFound";
import Spinner from "components/Spinner";
import { RootState } from "store/store";
import { ToastContainer, Slide, toast } from "react-toastify";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import IAuthMeResponse from "models/auth-me-response.interface";
import ConfirmPopup from "components/ConfirmPopup";
import CampaignPopup from "components/CampaignPopup";
import ExportPopup from "components/ExportPopup";
import { useDarkMode } from "hooks/useDarkMode";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import { initializeApp } from "firebase/app";

library.add(fas, far);

interface IAppProps extends PropsFromRedux {}

const mapStateToProps = ({ user }: RootState) => ({
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const App: React.FunctionComponent<IAppProps> = ({ user }) => {
  const [isSetAuthentication, setAuthentication] = React.useState(false);
  const dispatch = useDispatch();
  // const [currentTheme, setTheme] = React.useState("light");

  const [theme, themeToggler, mountedComponent] = useDarkMode();

  const tawkMessengerRef = React.useRef();

  const handleMinimize = () => {
    if (tawkMessengerRef.current) {
      (tawkMessengerRef.current as any).minimize();
    }
  };

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: `${process.env.FIREBASE_API_KEY}`,
    authDomain: "ai-activation-studio.firebaseapp.com",
    projectId: "ai-activation-studio",
    storageBucket: "ai-activation-studio.appspot.com",
    messagingSenderId: "1034775807519",
    appId: "1:1034775807519:web:f201fb4afe324c9805772a",
    measurementId: "G-F7KXQP56HT",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  React.useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");

    const setUser = async () => {
      setAuthentication(true);
      try {
        const response = await axiosForReverseAds.post<IAuthMeResponse>("auth/v2/me");
        const accountInfo = response.data.data;
        if (accessToken) {
          dispatch(
            setUserDetails({
              userName: accountInfo.userName,
              email: accountInfo.email,
              country: accountInfo.country,
              role: accountInfo.role,
              accessToken,
              id: accountInfo.id,
              website: accountInfo.website,
              businessId: accountInfo.businessId,
              isAuthenticated: true,
            })
          );
        }
      } catch (err: any) {
        if (err.response && err.response.data.message) {
          if (accessToken && err.response.data.message === "Unauthenticated.") {
            window.localStorage.removeItem("accessToken");
          }
        } else {
          toast.error("System Error");
        }
      }
      setAuthentication(false);
    };
    if (accessToken) {
      dispatch(getCountries());

      setUser();
    }
  }, []);

  const combinedTheme: DefaultTheme =
    theme === "dark" ? { ...defaultTheme, ...darkTheme } : { ...defaultTheme, ...lightTheme };

  return (
    <ThemeProvider theme={combinedTheme}>
      <GlobalStyle />
      {/* <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
      /> */}
      <Router>
        <Routes>
          <Route path="/" element={<IndexNavigation />} />
          <Route
            path={`${AUTH_ROUTES.AUTH_BASE_ROUTE}/*`}
            element={<AuthModule currentTheme={theme} />}
          />
          <Route
            path={`${USER_SETTING_ROUTES.BASE_ROUTE}/*`}
            element={
              <AuthWrapper sidebarLinks={[]}>
                <UserSettingModule theme={theme} themeToggler={themeToggler} />
              </AuthWrapper>
            }
          />

          <Route
            path={`${ADMIN_APP_ROUTES.APP_BASE_ROUTE}/*`}
            element={
              <AuthWrapper sidebarLinks={ADMIN_APP_ROUTES.sidebarLinks}>
                <AdminAppModule />
              </AuthWrapper>
            }
          />
          <Route
            path={`${CLIENT_APP_ROUTES.APP_BASE_ROUTE}/*`}
            element={
              <AuthWrapper
                sidebarLinks={CLIENT_APP_ROUTES.sidebarLinks}
                topbarRightLinks={CLIENT_APP_ROUTES.topbarRightLinks}
              >
                <ClientAppModule theme={theme} />
              </AuthWrapper>
            }
          />

          <Route path="*" element={isSetAuthentication ? <Spinner /> : <PageNotFound />} />
        </Routes>
      </Router>

      <ExportPopup />
      <CampaignPopup />
      <ConfirmPopup />
      <ToastContainer hideProgressBar autoClose={2000} transition={Slide} />
    </ThemeProvider>
  );
};

export default connector(App);
