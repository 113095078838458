import React from "react";

const SearchIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="17px"
      height="17px"
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0.4000244 0.4000244)">
        <path
          d="M7 0C3.1403 0 0 3.1403 0 7C0 10.8599 3.1403 14 7 14C8.69768 14 10.2561 13.3926 11.4694 12.3836L14.8963 15.8106C15.0226 15.9368 15.1879 16 15.3534 16C15.5187 16 15.6843 15.9368 15.8106 15.8106C16.0631 15.5582 16.0631 15.1487 15.8106 14.8963L12.3836 11.4694C13.3926 10.2561 14 8.69768 14 7C14 3.1403 10.8599 0 7 0ZM7 12.7077C3.8528 12.7077 1.29231 10.1472 1.29231 7.00003C1.29231 3.85283 3.8528 1.29231 7 1.29231C10.1472 1.29231 12.7077 3.8528 12.7077 7C12.7077 10.1472 10.1472 12.7077 7 12.7077Z"
          fill="#ffffff"
          fillRule="evenodd"
          stroke="#ffffff"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
