import React from "react";

const ManageCampaignIcon: React.FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.58 17.69">
      <defs>
        <style>
          {".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <polyline className="icon-svg" points="3.98 0.5 18.08 17.19 0.5 17.19 14.59 0.5" />
          <line className="icon-svg" x1={9.31} y1={6.81} x2={9.31} y2={0.5} />
        </g>
      </g>
    </svg>
  );
};

export default ManageCampaignIcon;
