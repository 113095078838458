import styled, { css } from "styled-components/macro";

export const SaveListButton = styled.div<{ disabled: boolean }>`
  display: flex;
  gap: 10px;
  font-size: 10px;
  padding: 5px 10px;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.blue};
  color: #ffffff;

  > svg {
    width: 10px;
    height: 10px;

    .icon-svg {
      stroke: #ffffff;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const KeywordListItemStyled = styled.div<{ $open: boolean; $loading: boolean }>`
  background: ${({ theme }) => theme.inputBorder};
  border-radius: 10px;
  transition: 0.1s;
  color: ${({ theme }) => theme.contrast};
  font-size: 10px;
  cursor: pointer;

  .list-wrapper {
    overflow: hidden;
    transition: 0.3s;
    max-height: ${({ $open }) => ($open ? 158 : 0)}px;
    height: ${({ $loading }) => ($loading ? "158px" : "auto")};
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;

    .empty {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .table-header,
  .keyword-data-row {
    .checkbox-wrapper {
      //
    }
  }

  .list-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px ${({ $open }) => ($open ? "5px" : "12px")} 15px;

    .delete-list,
    .delete-icon {
      display: flex;

      > svg {
        width: 12px;
        height: 12px;
        transform: translate(0px, -1px);

        .icon-svg {
          transition: 0.1s;
          stroke: ${({ theme }) => theme.contrast};
        }
      }
    }

    .delete-list {
      position: absolute;
      border-radius: 5px;
      padding: 3px 6px;
      top: 10px;
      right: 15px;
      display: flex;
      align-items: center;
      gap: 5px;
      border: 1px solid ${({ theme }) => theme.checkbox};
      transition: 0.1s;
    }

    .list-name {
      display: flex;

      > svg {
        width: 15px;
        height: 15px;

        .icon-svg {
          transition: 0.1s;
          /* fill: ${({ theme }) => theme.contrast}; */
          stroke: ${({ theme }) => theme.contrast};
        }
      }

      .name {
        margin-left: 10px;
      }
    }
  }

  .list-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: ${({ $open }) => ($open ? "visible" : "hidden")};
    max-height: 158px;

    .table-header {
      margin: 0 15px;

      display: grid;
      grid-template-columns: 25px 150px 1fr 1fr 40px 50px 50px;
      padding-right: 8px;
      margin-top: ${({ $open }) => ($open ? 10 : 3)}px;
      padding-bottom: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.tableBorder};
      transition: 0.1s;

      > span {
        &:nth-child(1) {
        }
        &:nth-child(2) {
        }
        &:nth-child(3) {
          text-align: right;
        }
        &:nth-child(4) {
          text-align: right;
        }
        &:nth-child(5) {
          text-align: center;
        }
        &:nth-child(6) {
          text-align: center;
        }
        &:nth-child(7) {
          text-align: center;
        }
      }

      .icon-wrapper {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;

        .icon-svg {
          transition: 0.1s;
          fill: ${({ theme }) => theme.cpc};
        }

        .icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .table-content {
      margin: 0 15px;
      overflow-y: ${({ $loading }) => ($loading ? "hidden" : "auto")};
      color: ${({ theme }) => theme.targeting};

      .keyword-data-row {
        display: grid;
        grid-template-columns: 25px 150px 1fr 1fr 40px 50px 50px;
        padding: 5px 0;
        border-bottom: 1px solid ${({ theme }) => theme.tableBorder};
        transition: 0.1s;

        > span {
          transition: 0.1s;

          &:nth-child(1) {
          }
          &:nth-child(2) {
          }
          &:nth-child(3) {
            text-align: right;
          }
          &:nth-child(4) {
            text-align: right;
            color: lightblue;
          }
          &:nth-child(5) {
            text-align: center;
            color: ${({ theme }) => theme.color.gold};
          }
          &:nth-child(6) {
            text-align: center;
          }
          &:nth-child(7) {
            text-align: center;
            color: ${({ theme }) => theme.cpc};
          }
        }
      }
    }

    .table-actions {
      padding: 10px 15px 12px 15px;
      display: flex;
      gap: 10px;

      .delete-keywords {
        border-radius: 5px;
        font-size: 10px;
        display: flex;
        gap: 10px;
        align-items: center;

        > svg {
          width: 10px;
          height: 10px;
          transform: translate(0px, -1px);

          .icon-svg {
            stroke: ${({ theme }) => theme.contrast};
          }
        }
      }

      .dropdown-toggle {
        padding: 0;
      }

      .cadet-down {
        border: #ffffff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
      }
    }
  }
`;
