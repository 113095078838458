import styled, { css } from "styled-components/macro";

export const UserAppHeaderStyled = styled.div<{ disabled?: boolean; error?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  background: black;
  padding: 10px;
  height: 64px;

  .url-field-error {
    font-size: 10px;
    position: absolute;
    bottom: -8px;
    left: 45px;
    color: crimson;
    font-weight: bold;
  }

  .disable-message {
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 20px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      .searchGroup {
        pointer-events: none;
      }
    `}

  .form-wrapper {
    width: 50%;
    min-width: 300px;
    position: relative;
  }

  .searchGroup {
    position: relative;
    margin-bottom: 0;

    .react-dropdown-select,
    .keyword-search-bar {
      font-size: ${({ theme }) => theme.size.xxl} !important;
      border-color: transparent !important;
      &:focus {
        border-color: transparent !important;
      }
      border-radius: 16px !important;
      padding: 0 45px !important;
      height: 40px;
      background: transparent;
      color: #ffffff;
      box-shadow: none !important;
    }

    .react-dropdown-select-input {
      min-width: 100px;
      font-size: 1em;
    }

    .react-dropdown-select-dropdown,
    .react-dropdown-select-dropdown {
      display: none;
    }

    .icon {
      width: 14px;
      height: 14px;
      left: 20px;
      position: absolute;
      top: 15px;
      z-index: 2;
    }
    .icon.left {
      left: 25px;
    }
    .icon.right {
      right: 25px;
    }
  }

  .history {
    margin-left: 20px;

    a {
      display: block;
      text-align: center;
      color: #9ba6b2;
      font-size: 0.6875em;
    }
    .iconWrapper {
      background: #c0dff9;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      margin-bottom: 3px;
    }
  }

  .historyIcon {
    max-width: 19px;
  }

  .traffic-info {
    padding: 8px;
    margin: 0;
    font-size: 0.875em;
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    .searchGroup {
      .form-control {
        width: 100%;
      }
    }
  }
`;
