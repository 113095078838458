import React from "react";

const FolderUpIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 18.29">
      <defs>
        <style>
          {
            ".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.88px;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <polyline className="icon-svg" points="7.07 12.15 10.83 7.94 14.59 12.15" />
          <path
            className="icon-svg"
            d="M9.28.88l2.15,2h9.34V17.42L.88,17.28V.88h8.4m0-.88H.88A.87.87,0,0,0,0,.88v16.4a.88.88,0,0,0,.87.88l19.9.13h0a.87.87,0,0,0,.88-.87V2.83A.87.87,0,0,0,20.77,2h-9L9.87.23A.86.86,0,0,0,9.28,0Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default FolderUpIcon;
