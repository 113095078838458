import SuggestedKeywords from "./pages/SuggestedKeywords";
import UserCampaigns from "./pages/UserCampaigns";
import PaymentResult from "./pages/PaymentResult";
import NewsFeed from "./pages/NewsFeed";
import Home from "./pages/Home";

import PricingPlan from "./pages/PricingPlan";
import { ConnectedComponent } from "react-redux";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import NewsFeedDetail from "./pages/NewsFeedDetail";
import { ManageCampaignIcon, HomeIcon } from "components/Icons";
import {
  showBuildCampaign,
  showComparePopup,
  showCompetitorPopup,
  showSuggestedPopup,
} from "store/actions/popup.actions";

import CompareIcon from "components/Icons/CompareIcon";
import SuggestedIcon from "components/Icons/SuggestedIcon";
import CompetitorIcon from "components/Icons/CompetitorIcon";
import PlusIcon from "components/Icons/PlusIcon";

export const APP_BASE_ROUTE = "app";
export const APP_HOME = `home`;
export const KEYWORDS_RESEARCH = `keywords/research/`;
export const SUGGESTED_KEYWORDS = `keywords/suggested/`;
export const USER_CAMPAIGNS = `campaigns/`;
export const ADD_CAMPAIGN = `campaign/add/`;
export const CAMPAIGN_NEW = `campaign/new/`;
export const EDIT_CAMPAIGN = `campaign/:campaignId/edit/:step`;
export const CAMPAIGN_REPORT = `campaign/:campaignId`;
export const KEYWORD_HISTORY = `keyword-history/`;
export const WEBSITE_ANALYSIS = `website-analysis/`;
export const SAVED_KEYWORDS = `saved-keywords/`;
export const SAVED_KEYWORDS_ITEMS = `saved-keywords/:id`;
export const CHECKOUT = `checkout/`;
export const PAYMENT = `campaign/:campaignId/payment`;
export const PAYMENT_RESULT = `payment-result`;
export const PRICING = `pricing`;
export const NEWS_FEED = `feed/`;
export const NEWS_FEED_ITEM = `feed/:id`;

export interface INavIcon {
  type: string;
  name: IconProp;
}

export interface INavigation {
  path: string;
  text: string;
  icon: ConnectedComponent<any, any> | React.FunctionComponent<any>;
  activeLinks?: string[];
  action?: () => void;
}

export interface INavigationGroup {
  text: string;
  collapsed?: boolean;
  children?: INavigation[];
  path?: string;
  icon?: React.ReactNode;
  activeLinks?: string[];
  imagePath?: string;
}

export interface IRoute {
  path: string;
  component: ConnectedComponent<any, any> | React.FunctionComponent<any>;
}

export const userAppRoutes: Array<IRoute> = [
  {
    path: PRICING,
    component: PricingPlan,
  },
  {
    path: PAYMENT_RESULT,
    component: PaymentResult,
  },
  {
    path: SUGGESTED_KEYWORDS,
    component: SuggestedKeywords,
  },

  {
    path: USER_CAMPAIGNS,
    component: UserCampaigns,
  },
  {
    path: NEWS_FEED,
    component: NewsFeed,
  },
  {
    path: `${NEWS_FEED_ITEM}:id`,
    component: NewsFeedDetail,
  },
  { path: APP_HOME, component: Home },
];

const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
let sidebarLinks_local = [] as Array<INavigationGroup>;

if (LOCAL_DOMAINS.includes(window.location.hostname)) {
  sidebarLinks_local = [
    {
      text: "In Development",
      collapsed: true,
      children: [
        // {
        //   path: CAMPAIGN_NEW,
        //   component: Campaign,
        //   text: "New Campaign",
        //   icon: { type: "fontAwesome", name: ["fas", "chart-line"] },
        // },
        {
          path: USER_CAMPAIGNS,
          text: "Manage Campaigns",
          icon: ManageCampaignIcon,
          activeLinks: [USER_CAMPAIGNS, ADD_CAMPAIGN, CAMPAIGN_REPORT],
        },
      ],
    },
  ];
} else {
  sidebarLinks_local = [];
}
export const sidebarLinks: Array<INavigationGroup> = [
  {
    text: "",
    collapsed: true,
    children: [
      {
        path: APP_HOME,
        text: "Home",
        icon: HomeIcon,
        activeLinks: [APP_HOME],
      },
    ],
  },

  //
  {
    text: "Keyword Tools",
    collapsed: true,
    children: [
      {
        path: SUGGESTED_KEYWORDS,
        text: "Suggested",
        icon: SuggestedIcon,
        activeLinks: [SUGGESTED_KEYWORDS],
        action: showSuggestedPopup,
      },
      {
        path: KEYWORDS_RESEARCH,
        text: "Compare",
        icon: CompareIcon,
        activeLinks: [KEYWORDS_RESEARCH],
        action: showComparePopup,
      },

      {
        path: WEBSITE_ANALYSIS,
        text: "Competitor",
        icon: CompetitorIcon,
        activeLinks: [WEBSITE_ANALYSIS],
        action: showCompetitorPopup,
      },
    ],
  },
  {
    text: "Advertise",
    collapsed: true,
    children: [
      {
        path: CAMPAIGN_NEW,
        action: showBuildCampaign,
        text: "New Campaign",
        icon: PlusIcon,
      },
      {
        path: USER_CAMPAIGNS,
        text: "Manage Campaigns",
        icon: ManageCampaignIcon,
        activeLinks: [USER_CAMPAIGNS, ADD_CAMPAIGN, CAMPAIGN_REPORT, "campaign"],
      },
    ],
  },
  ...sidebarLinks_local,
];

export interface IBottomLink {
  path: string;
  text: string;
  imagePath: string;
  activeLinks: string[];
  icon?: INavIcon;
}

export interface ITopbarLink {
  path?: string;
  text: string;
  activeLinks?: string[];
  children?: Array<{
    path: string;
    text: string;
    icon?: ConnectedComponent<any, any> | React.FunctionComponent<any>;
    activeLinks: string[];
  }>;
}

export const topbarLeftLinks: Array<ITopbarLink> = [
  {
    path: "#AdvertisingTips",
    text: "Advertising Tips",
    activeLinks: ["#AdvertisingTips"],
  },
  {
    path: "#Blog",
    text: "Blog",
    activeLinks: ["#Blog"],
  },
  {
    path: "#Packages",
    text: "Packages",
    activeLinks: ["#Packages"],
  },
  {
    text: "Help Center",
    children: [
      {
        path: "#Action",
        text: "Action",
        activeLinks: ["#Action"],
      },
      {
        path: "#Something",
        text: "Something",
        activeLinks: ["#Something"],
      },
    ],
  },
];

export const topbarRightLinks = [] as Array<INavigation>;
